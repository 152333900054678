<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
  <CRow>
    <CCol>
      <CForm>
        <CCard>
          <CCardHeader>
            <strong>{{ $t('channel.channelCreate') }}</strong>
          </CCardHeader>
          <CCardBody>
            <template>
              <CRow class="object-card-row">
                <CCol sm="3" class="font-weight-bold">{{ $t('channel.bchNet') }}</CCol>
                <CCol sm="9">
                  <span class="ml-2" v-if="currentNetwork">{{ currentNetwork.name }}</span>
                </CCol>
              </CRow>
              <CInput :label="$t('channel.programId')" horizontal addLabelClasses="font-weight-bold"
                v-model="channel.codeName" :isValid="isValid.codeName" :tooltipFeedback=false
                :invalidFeedback="errorMessageForCodeName" @change="$v.channel.codeName.$touch()" />

              <CSelect :label="$t('channel.policyChangeState')" :options="[
                { value: policy.EXCLUSIVE.code, label: policy.EXCLUSIVE.text },
                { value: policy.OR.code, label: policy.OR.text },
                { value: policy.MAJORITY.code, label: policy.MAJORITY.text }
              ]" horizontal required :placeholder="$t('channel.selectPolicy')" addLabelClasses="font-weight-bold"
                :value.sync="channel.policy" :isValid="isValid.policy" :tooltipFeedback=false
                :invalidFeedback="validationMessages.required" @change="$v.channel.policy.$touch()" />
              <CSelect :label="$t('channel.policyLifeCycle')" :options="[
                { value: policy.EXCLUSIVE.code, label: policy.EXCLUSIVE.text },
                { value: policy.OR.code, label: policy.OR.text },
                { value: policy.MAJORITY.code, label: policy.MAJORITY.text }
              ]" horizontal required :placeholder="$t('channel.selectPolicy')" addLabelClasses="font-weight-bold"
                :value.sync="channel.lifeCyclePolicy" :isValid="isValid.lifeCyclePolicy" :tooltipFeedback=false
                :invalidFeedback="validationMessages.required" @change="$v.channel.lifeCyclePolicy.$touch()" />
            </template>
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" :disabled="!canCreate" @click.prevent="createDraftAndContinue()">{{
                $t('common.next')
            }}
              <fa-icon icon="angle-double-right" size="sm" />
            </CButton>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";

import { required } from 'vuelidate/lib/validators';

import messages from '../messages';
import utilMessages from '../../../utils/messages';
import { isValidCodeName } from '@/utils/validators';
import { ChannelConfigUpdateType } from '../common/transaction/constants';


export default {
  name: 'ChannelCreate',
  data() {
    return {
      allNetworks: [],
      validationMessages: messages.validation,
      channel: {
        blockchainNetwork: null,
        codeName: null,
        policy: null,
        lifeCyclePolicy: null,
      },
      network_status: null,
      policy: ChannelConfigUpdateType,
    }
  },
  validations: {
    channel: {
      blockchainNetwork: {
        required
      },
      codeName: {
        required,
        isValidCodeName,
      },
      policy: {
        required
      },
      lifeCyclePolicy: {
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
    ]),
    isValid() {
      let blockchainNetwork, codeName, policy, lifeCyclePolicy
      if (this.$v.channel.blockchainNetwork.$dirty)

        blockchainNetwork = !this.$v.channel.blockchainNetwork.$invalid

      if (this.$v.channel.codeName.$dirty)
        codeName = !this.$v.channel.codeName.$invalid

      if (this.$v.channel.policy.$dirty)
        policy = !this.$v.channel.policy.$invalid

      if (this.$v.channel.lifeCyclePolicy.$dirty)
        lifeCyclePolicy = !this.$v.channel.lifeCyclePolicy.$invalid

      return {
        blockchainNetwork: blockchainNetwork,
        codeName: codeName,
        policy: policy,
        lifeCyclePolicy: lifeCyclePolicy,
      }
    },
    canCreate() {
      return !this.$v.channel.$invalid
    },
    currentNetwork() {
      return this.allNetworks.find(net => net.id === this.getUser.blockchain_net_id)
    },
    errorMessageForCodeName() {
      if (!this.$v.channel.codeName.required) {
        return this.validationMessages.required
      } else if (!this.$v.channel.codeName.isValidCodeName) {
        return this.validationMessages.codeName
      }
    }
  },
  async mounted() {
    await this.getAllStartedNetworks()
    this.channel.blockchainNetwork = this.getUser.blockchain_net_id
  },
  methods: {
    async getAllStartedNetworks() {
      try {
        this.allNetworks = await this.$store.dispatch('fetchNetworks', { 'view': 'organization', 'status': 'started' })
      }
      catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }
    },
    async createDraftAndContinue() {

      const channelData = {
        code_name: this.channel.codeName,
        channel_update_policy_type: this.channel.policy,
        blockchain_net_id: this.channel.blockchainNetwork,
      };

      if (this.channel.lifeCyclePolicy)
        channelData.lifecycle_update_policy_type = this.channel.lifeCyclePolicy

      try {
        const channel = await this.$store.dispatch('createChannel', channelData)
        this.$toast.info(this.$i18n.t('channel.newChannelCreated'))
        this.$router.push({ path: `/channels/${channel.id}/draft` })

      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))

      }
    },
  },
}
</script>

<style>
.object-card-row {
  margin-bottom: 1rem;
}
</style>
