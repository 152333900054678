var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',[_c('CForm',[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(_vm.$t('channel.channelCreate')))])]),_c('CCardBody',[[_c('CRow',{staticClass:"object-card-row"},[_c('CCol',{staticClass:"font-weight-bold",attrs:{"sm":"3"}},[_vm._v(_vm._s(_vm.$t('channel.bchNet')))]),_c('CCol',{attrs:{"sm":"9"}},[(_vm.currentNetwork)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.currentNetwork.name))]):_vm._e()])],1),_c('CInput',{attrs:{"label":_vm.$t('channel.programId'),"horizontal":"","addLabelClasses":"font-weight-bold","isValid":_vm.isValid.codeName,"tooltipFeedback":false,"invalidFeedback":_vm.errorMessageForCodeName},on:{"change":function($event){return _vm.$v.channel.codeName.$touch()}},model:{value:(_vm.channel.codeName),callback:function ($$v) {_vm.$set(_vm.channel, "codeName", $$v)},expression:"channel.codeName"}}),_c('CSelect',{attrs:{"label":_vm.$t('channel.policyChangeState'),"options":[
              { value: _vm.policy.EXCLUSIVE.code, label: _vm.policy.EXCLUSIVE.text },
              { value: _vm.policy.OR.code, label: _vm.policy.OR.text },
              { value: _vm.policy.MAJORITY.code, label: _vm.policy.MAJORITY.text }
            ],"horizontal":"","required":"","placeholder":_vm.$t('channel.selectPolicy'),"addLabelClasses":"font-weight-bold","value":_vm.channel.policy,"isValid":_vm.isValid.policy,"tooltipFeedback":false,"invalidFeedback":_vm.validationMessages.required},on:{"update:value":function($event){return _vm.$set(_vm.channel, "policy", $event)},"change":function($event){return _vm.$v.channel.policy.$touch()}}}),_c('CSelect',{attrs:{"label":_vm.$t('channel.policyLifeCycle'),"options":[
              { value: _vm.policy.EXCLUSIVE.code, label: _vm.policy.EXCLUSIVE.text },
              { value: _vm.policy.OR.code, label: _vm.policy.OR.text },
              { value: _vm.policy.MAJORITY.code, label: _vm.policy.MAJORITY.text }
            ],"horizontal":"","required":"","placeholder":_vm.$t('channel.selectPolicy'),"addLabelClasses":"font-weight-bold","value":_vm.channel.lifeCyclePolicy,"isValid":_vm.isValid.lifeCyclePolicy,"tooltipFeedback":false,"invalidFeedback":_vm.validationMessages.required},on:{"update:value":function($event){return _vm.$set(_vm.channel, "lifeCyclePolicy", $event)},"change":function($event){return _vm.$v.channel.lifeCyclePolicy.$touch()}}})]],2),_c('CCardFooter',[_c('CButton',{attrs:{"color":"primary","disabled":!_vm.canCreate},on:{"click":function($event){$event.preventDefault();return _vm.createDraftAndContinue()}}},[_vm._v(_vm._s(_vm.$t('common.next'))+" "),_c('fa-icon',{attrs:{"icon":"angle-double-right","size":"sm"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }